import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Flag from 'react-world-flags';
import Flip from 'react-reveal/Flip';

import Layout from '../components/layout';
import SEO from '../components/seo';
import programmation from '../pdf/PROGRAMMATION-CINELANGUES-ITALIEN.pdf';

export const pageQuery = graphql`
  query {
    films: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/films/**/*.md" }
        frontmatter: { category: { eq: "Italien" } }
      }
      sort: { order: DESC, fields: [frontmatter___time] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          html
          excerpt
          frontmatter {
            title
            meta
            time
            classe
            affiche {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400, maxHeight: 540) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ItalienPage = ({ data }) => (
  <Layout>
    <SEO
      title="Films Italiens 2022 - Cinelangues"
      description="Retrouvez tous nos films italiens."
      pathname="/italien-les-films-cinelangues/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-8 header-content my-auto">
            <h1 className="ml-2">
              <Flag code="it" height="30" className="ml-2 mr-2" /> Films Italiens
            </h1>
          </div>
          <div className="col-sm-12 col-md-4 my-auto text-right">
            <a
              type="button"
              className="mr-2 mt-1 ml-2"
              data-toggle="modal"
              data-target="#exampleModal"
              href="#"
            >
              <i className="fa fa-edit"></i>&nbsp;&nbsp;Présentation
            </a>
            <a
              href={programmation}
              target="_blank"
              className="mt-1"
              rel="norefer noopener"
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Programmation PDF
            </a>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">Programmation Italien 2023-2024</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="small">
                  <strong>Cette année, nous poursuivons les changements initiés l’année dernière en vous proposant une sélection de films à l’année, que vous pourrez programmer vous-mêmes quand vous le souhaitez, en fonction de vos choix pédagogiques.</strong>
                </p>
                <p className="small">Nous nous sommes efforcées de renouveler l’offre, en profitant d’une actualité riche en sorties de films italiens. L’ensemble des œuvres nous permettra d’explorer le monde de l’enfance et de l’adolescence et celui des figures féminines d’exception. Nous vous proposons également un film incontournable qui ne s’inscrit pas dans ces deux thématiques, mais qui s’impose par ses multiples qualités : L’Enlèvement de Marco Bellocchio.</p>
                <p className="small">Pour le collège Interdits aux chiens et aux italiens sera disponible aussi bien dans la version originale plurilingue que dans la version totalement en italien, selon votre demande. Il propose un regard original sur la thématique du voyage et de l’émigration qui sera reprise par Cino, l’enfant qui traversa les montagnes. Film poétique, sur fond d’émigration enfantine, mais également aventure entre fantastique et réalité  qui pourra être proposé à vos élèves dès la 6ème. Les aventures de Pinocchio reste un incontournable de la culture italienne, beau récit d’initiation, qui propose une relecture moderne et profonde du conte de Collodi.</p>
                <p className="small">Pour les lycéens, A chiara, qui faisait déjà partie de notre offre de l’année dernière, sera enrichi de quatre autres films, tous réalisés par des femmes, afin de vous proposer un large choix de portraits de femmes fortes et de figures féminines d’exception. Primadonna s’inspire de l’histoire vraie de la première Sicilienne qui a refusé le mariage réparateur et a ouvert la voie à la lutte pour les droits des femmes. Gloria ! nous transporte à Venise, au 18e siècle, à l’Institut Sant'Ignazio, orphelinat et conservatoire pour jeunes filles. C’est là que Teresa, une jeune domestique, découvre l’instrument idéal pour exprimer sa créativité et la faire éclater au grand jour. Il reste encore demain, tout en nous plongeant dans la ville de Rome en 1946, aborde la question toujours actuelle des droits des femmes, grâce à Delia, qui, sous une apparente docilité, nous mène là où l’on ne s’y attend pas. Enfin, La Nouvelle femme offre une plongée dans la vie de Maria Montessori, une femme de trente ans indépendante et éminemment moderne, en Italie au début du XXe siècle, à qui nous devons une approche pédagogique révolutionnaire et visionnaire.</p>
                <p className="small">Les lycéens pourront ensuite découvrir, à travers un film singulier, L’Enlèvement, l’un des chapitres les plus sombres et sanglants de l’histoire d’Italie en plein « Risorgimento ». Dans ce contexte, la police pontificale de Bologne arrache le petit Edgardo Mortara à sa famille de confession juive sous prétexte qu’il aurait été baptisé en secret alors qu’il était encore bébé.</p>
                <p className="small">Nous espérons rencontrer votre adhésion et vous permettre de profiter, une année encore, du plaisir et de la richesse du cinéma.</p>
                <p className="small">
                  <strong>Silvia Balea</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container films">
        <div className="row">
          <div className="col-lg-12">
            <div className="row no-gutter">
              {data.films.edges.map(({ node: post }) => (
                <div key={post.id} className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <Flip right cascade>
                    <div className="card">
                      <Link to={post.fields.slug}>
                        <Img
                          fluid={post.frontmatter.affiche.childImageSharp.fluid}
                          alt={post.frontmatter.title}
                        />
                        <div className="text">
                          <div className="row no-gutter">
                            <h2 className="card-title text-capitalize col-12 col-sm-5 col-md-7">
                              <Flag code="it" height="11" />
                              {post.frontmatter.title}
                            </h2>
                            <div className="col-sm-7 col-md-5 badges">
                              {post.frontmatter.time != 'NA' ? (
                                <span className="badge badge-pill badge-danger">
                                  {post.frontmatter.time}
                                </span>
                              ) : null}
                              <span className="badge badge-pill badge-warning">
                                {post.frontmatter.classe}
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <small>{post.frontmatter.meta}</small>
                            </div>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: post.excerpt }}
                              ></div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Flip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ItalienPage;
